import React from 'react'
import { Link } from 'react-router-dom'

function Navbar() {
    return (
        <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
            <Link class="navbar-brand" to="/">Souvik</Link>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNav">
                <ul class="navbar-nav">
                    <li class="nav-item">
                        <Link class="nav-link" to="/">Home <span class="sr-only">(current)</span></Link>
                    </li>
                    <li class="nav-item">
                        <Link class="nav-link" to="/certificate">Certificates</Link>
                    </li>
                    {/* <li class="nav-item">
                        <Link class="nav-link" to="/blog">Blog</Link>
                    </li> */}
                    <li class="nav-item">
                        <Link class="nav-link" to="/project">Projects</Link>
                    </li>
                </ul>
            </div>
        </nav>
    )
}

export default Navbar