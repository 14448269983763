import React from 'react'
import Axios from 'axios'
import ProjectHolder from '../components/ProjectHolder'


class ProjectPage extends React.Component{
    constructor(){
        super()
        this.state= {
            name: {},
            projects: []
        }

    }
    
    componentWillMount(){
        Axios({
            method: "GET",
            url: "https://portfolio-serverjs.herokuapp.com/projects",
            data: {
                code: process.env.REACT_APP_PROJECT_CODE
            }
        }).then(response=>{
            this.setState({
                projects: response.data
            })
        }).catch(err=>{
            console.log(err)
        })
    }

    render(){
        return(
            <div>
                <div className="">
                    <div className="dict">
                    {
                    this.state.projects.map(project=>{
                        return <ProjectHolder title={project.title} body={project.body} link={project.link}/>
                    })
                }
                    </div>
                </div>
            </div>
        )
    }
}

export default ProjectPage