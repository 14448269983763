import React from 'react'


class AddProjPage extends React.Component {
    constructor() {
        super()
        this.state = {
            code: "",
            title: "",
            body: "",
            link: ""
        }
        this.codehandleChange = this.codehandleChange.bind(this)
        this.titleHandelChange = this.titleHandelChange.bind(this)
        this.bodyHandleChange = this.bodyHandleChange.bind(this)
        this.linkHandleChange = this.linkHandleChange.bind(this)
    }

    codehandleChange(event) {
        this.setState({
            code: event.target.value,
        })
    }
    titleHandelChange(event){
        this.setState({title: event.target.value})
    }
    bodyHandleChange(event){
        this.setState({body: event.target.value})
    }
    linkHandleChange(event){
        this.setState({link: event.target.value})
    }



    submitButton() {
        const project = {
            title: this.state.title,
            body: this.state.body,
            link: this.state.link
        }
        if(this.state.code!=process.env.REACT_APP_PROJECT_CODE){
            alert("Faliure")
            return
        }
    }

    render() {
        return (
            <div className="m-10">
                <center>
                    
                    <div className="container">
                        <div className="row justify-content-md-center">
                            <div className="col-6">
                                <div className="form-group">
                                    <input type="text" className="form-control"
                                        value={this.state.input}
                                        onChange={this.codehandleChange}
                                        placeholder="Code" />
                                </div>
                                <div className="form-group">
                                    <input type="text" className="form-control"
                                        value={this.state.input}
                                        onChange={this.titleHandelChange}
                                        placeholder="Title" />
                                </div>
                                <div className="form-group">
                                    <input type="text" className="form-control"
                                        value={this.state.input}
                                        onChange={this.linkHandleChange}
                                        placeholder="Link" />
                                </div>
                                <div className="form-group">
                                    <textarea type="text" className="form-control"
                                        value={this.state.input}
                                        onChange={this.bodyHandleChange}
                                        placeholder="Body" rows="6" cols="" />
                                </div>

                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <button className="btn btn-dark" onClick={() => { this.submitButton() }}>Submit</button>
                            </div>
                        </div>
                    </div>
                </center>
            </div>
        )
    }
}

export default AddProjPage