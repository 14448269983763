const cert = [
    {
        title: "2020 web developement bootcamp",
        body : "I did this course from Udemy",
        link: "",
        image: "./web_dev_bootcamp.jpg"
    },
    {
        title: "Machine learning",
        body: "I cleared the first round in a competetion held by IIT Roorkee",
        link: "",
        image: "./dique.jpg"
    },{
        title: "Cpp algorithm",
        body: "I did this course from Udemy",
        link: "",
        image: "./Cpp.jpg"
    }
]

export default cert