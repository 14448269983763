import React from 'react'
import Navbar from '../components/Navbar'
import me from '../images/me.svg'
import linkedin from '../images/linkedin.svg'
import twitter from '../images/icons8-twitter.svg'
import github from '../images/Image 1.svg'
//==================================================//

class Mainpage extends React.Component {
    constructor(){
        super()
    }

    componentWillMount(){
    }

    render() {
        return (
            <div>
                <div className="container landing-page">
                    <div className="row">
                        <div className="col-md-6">
                            <center>
                                <img src={me} alt="" width="280px" />
                            </center>
                        </div>
                        <div className="col-md-6 right-tb">
                            <h3>Hi, I am Souvik</h3>
                            <p>I am a budding developer, always learning and creating
                something new. Please have a look at some of the <a href="">projects </a>
                that I have built.
              </p>
                            <h3>Contact Me</h3>
                            <div className="row">
                                <div className="col-2 col-md-1">
                                    <a href="https://www.linkedin.com/in/souvik-de-a2b941169/" target="blank"><img src={linkedin} alt="" width="35px" /></a>
                                </div>
                                <div className="col-2 col-md-1">
                                    <a href="https://twitter.com/souvik_ns" target="blank"><img src={twitter} alt="" width="35px" /></a>
                                </div>
                                <div className="col col-md">
                                    <a href="https://github.com/Souvikns" target="blank"><img src={github} alt="" width="35px" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Mainpage