import React from 'react'
import CHolder from '../components/Certificateholder'
import Cert from '../model/certtificate'
const images = require.context('../../public/certificates',true)

class CertificatePage extends React.Component{
    render(){
        return(
            <div>
                
                <div className="dict">
                    {
                        Cert.map(data=>{
                            return(
                                <CHolder title={data.title} body={data.body} image={images(data.image)}/>
                            )
                        })
                    }
                </div>
            </div>
        )
    }
}


export default CertificatePage