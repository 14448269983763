import React from "react";
import { Link } from "react-router-dom";
import me from "../images/me.svg";

function Certificateholder(props) {
  const style = {
    width: "18rem"
  };
  return (
    <div>
    <div className="card" style={style}>
              <img src={props.image} alt="" className="card-img-top" />
              <div className="card-body">
                <h5 className="card-title">{props.title}</h5>
                <p className="card-text">{props.body}</p>
                <a href={props.link} className="card-link">
                  Read
                </a>
              </div>
            </div>
    </div>
  );
}

export default Certificateholder;
