import React from 'react';
import Mainpage from './pages/Mainpage';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom'
import CertificatePage from './pages/CertificatePage';
import Navbar from './components/Navbar';
import BlogPage from './pages/BlogPage'
import ProjectPage from './pages/ProjectPage'
import AddProject from './pages/AddProjPage'



class App extends React.Component {

  render() {
    return (
      <Router>
        <div>
          <Navbar/>
          <Switch>
            <Route exact path="/">
              <Mainpage />
            </Route>
          </Switch>

          <Switch>
            <Route path="/certificate">
              <CertificatePage />
            </Route>
          </Switch>
          <Switch>
            <Route path="/project">
            <ProjectPage/>
            </Route>
          </Switch>
          <Switch>
            <Route path="/blog">
              <BlogPage/>
            </Route>
          </Switch>
          <Switch>
            <Route path="/addproject">
            <AddProject/>
            </Route>
          </Switch>
        </div>
      </Router>
    );
  }

}

export default App;
