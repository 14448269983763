import React from 'react'

class BlogPage extends React.Component{
    render(){
        return(
            <div>
                <h1>Blog Page</h1>
            </div>
        )
    }
}

export default BlogPage