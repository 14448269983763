import React from 'react'

function ProjectHolder(props){
    const style={
        width: "18rem",
        margin: "4.4rem 1.2rem"
    }
    return(
        <div className="card" style={style}>
            <div className="card-body">
                <h4 className="card-title">{props.title}</h4>
                <p className="card-text">{props.body}</p>
                <a href={props.link}>see</a>

            </div>
        </div>
    )
}


export default ProjectHolder
